<template>
  <b-container fluid>
    <div class="d-flex justify-content-center">
      <h1>Resumo dos Produtos</h1>
    </div>

    <b-row align-h="center">
      <b-col>
        <sumary-card
          title="CADASTRADOS"
          :value="sumary.productCount"
          color="#007bff"
        />
      </b-col>
      <b-col>
        <sumary-card
          title="SEM IMAGEM"
          :value="sumary.productWithoutImage"
          color="#3e4095"
        />
      </b-col>
      <b-col>
        <sumary-card
          title="SEM DESCRIÇÃO"
          :value="sumary.productWithoutDescription"
          color="#6f42c1"
        />
      </b-col>
      <b-col>
        <sumary-card
          title="SEM PESO"
          :value="sumary.productWithoutWeight"
          color="#e83e8c"
        />
      </b-col>
      <b-col>
        <sumary-card
          title="SEM ESTOQUE"
          :value="sumary.productWithoutStock"
          color="#dc3545"
        />
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-3">
      <b-col>
        <sumary-card
          title="SEM RELACIONADOS"
          :value="sumary.productsWithoutRelation"
          color="#cc1717"
        />
      </b-col>
      <b-col>
        <sumary-card
          title="SEM ATRIBUTOS"
          :value="sumary.productsWithoutAttributes"
          color="#fd7e14"
        />
      </b-col>
      <b-col>
        <sumary-card
          title="EM NOVIDADES"
          :value="sumary.productNews"
          color="#ffc107"
        />
      </b-col>
      <b-col>
        <sumary-card
          title="PREÇOS REDUZIDOS"
          :value="sumary.productPriceDown"
          color="#28a745"
        />
      </b-col>
      <b-col>
        <sumary-card
          title="PREÇOS COM AUMENTO"
          :value="sumary.productPriceUp"
          color="#17a2b8"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <sumary-card
          title="CADASTRADOS NOS ULTIMOS 7 DIAS"
          :value="sumary.latestProducts"
          color="#365314"
        />
      </b-col>
      <b-col>
        <sumary-card
          title="ULTIMOS ATUALIZADOS"
          :value="sumary.latestUpdatedProducts"
          color="#F87171"
        />
      </b-col>
      <b-col>
        <sumary-card
          title="INATIVOS"
          :value="sumary.productInactive"
          color="#38BDF8"
        />
      </b-col>
      <b-col>
        <sumary-card
          title="NÃO EXIBIDOS"
          :value="sumary.productNotShow"
          color="#475569"
        />
      </b-col>
      <b-col></b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapMutations } from "vuex";
import SumaryCard from "../components/dashboard/SumaryCard.vue";
import http from "../plugins/http";

export default {
  name: "dashboard",
  components: { SumaryCard },
  data() {
    return {
      sumary: {
        productCount: 0,
        productWithoutImage: 0,
        productWithoutDescription: 0,
        productWithoutWeight: 0,
        productWithoutStock: 0,
        productInactive: 0,
        productNotShow: 0,
        productNews: 0,
        productPriceDown: "",
        productPriceUp: "",
        latestProducts: 0,
        productsWithoutRelation: 0,
        latestUpdatedProducts: 0,
        productsWithoutAttributes: 0,
      },
    };
  },
  methods: {
    ...mapMutations(["setOverlay"]),
  },
  async created() {
    this.setOverlay(true);
    try {
      const { data: sumary } = await http.get("/sumary");
      this.sumary = sumary;
    } catch (err) {
      console.log(err);
    }
    this.setOverlay(false);
  },
};
</script>

<style lang="scss" scoped></style>
