<template>
  <div class="sumary-card" :style="`background-color: ${color}`">
    <h4>{{ value }}</h4>
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "SumaryCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    color: {
      type: String,
      requried: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sumary-card {
  color: white;

  height: 140px;
  max-width: 350px;

  padding: 0.4rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.4rem;

  border-radius: 0.2rem;

  h4 {
    font-size: 50px;
  }

  span {
    font-weight: 600;

    &::before {
      content: "";
      display: block;
      border-bottom: 5px solid white;
      border-radius: 0.25rem;
    }
  }

  &:hover {
    transform: scale(1.05);
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
}
</style>
